.completed_checkbox {
    margin-right: 8px !important;
}

/* order setting of the buttons */

.order_one { order: 1; }
.order_two { order: 2; }

/* .comm_detail_modal .ant-modal-content {
    width: max-content !important;
    
} */

.comm_detail_modal .ant-modal-body {
    width: min-content;
}

/* .comm_detail_modal .ant_modal {
    width: unset;
} */

.checkbox_cont .ant-checkbox-inner {
    width: 1.9em;
    height: 1.9em;
}