.ckeditor-parent .a.cke_path_item .span.cke_path_empty {
    display: none !important;
}

.ckeditor-parent .cke_path{
    display: none !important;
}

.ckeditor-parent .ant-modal-close {
    margin-top: 0.5em;
    color: black;
    font-weight: 600;
}

.description-input-coach-commitment {
    resize: none !important;
}

.title-input-coach-commitment {
    /* border-radius: unset !important; */
    border-radius: 0.5em !important;
    border-color: transparent;
    width: 100% !important;
    background-color: #f7f7f7 !important;

}

.description-input-coach-commitment {
    border: unset !important;
    border-color: transparent !important;
    border-radius: unset !important;
    border-radius: 0.5em !important;
    width: 100% !important; 
    height: 175px;
    padding: 2px !important;
    background-color: #f7f7f7 !important;

}

.desc_style {
    text-wrap: nowrap;
}

.collapse_main  {
    border-radius: 0.6em !important;
    border-bottom: none !important;
  }

.collapse_main .ant-collapse-content-box {
    border-bottom-right-radius: 0.6em !important;
    border-bottom-left-radius: 0.6em !important;
}

.checkbox_cont {
margin-bottom: 0.3em !important;
}

/* .checkbox_cont .ant-checkbox {
    width: 1.5em;
    height: 1.5em;
} */

.reco_btn {
    font-family: poppins, sans-serif;
    font-weight: 500;
    text-shadow: none;
    color: rgba(165, 196, 43, 1);
    background-color: rgba(255, 255, 255, 1);
}

.reco_btn:hover, .reco_btn:focus {
    color: rgba(165, 196, 43, 1);
    background-color: #fff;
}

.ckeditor-parent .ant-modal-content {
    background-color: transparent !important;
}

.ckeditor-parent .ant-modal-body {
    padding: 0 !important;
}

/* close button main */

.main_close_btn > svg {
    width: 1.4em;
    height: 1.4em;
    margin-right: -1.5em;
    margin-top: -0.5em;
}

.ckeditor-parent .ant-modal-content {
    box-shadow: none !important;
}

/* date picker styling */

.dpick_add_comm_coach .ant-picker {
    height: 35px;
}

/* modal for delete coaching commitment confirmation */

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.38) !important;
}