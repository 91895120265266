.survey-row{
  flex-direction: row;
}

.survey-row-reverse{
  flex-direction: row-reverse;
  margin-left: 2%;
}

.survey-row, .survey-row-reverse {
  justify-content: space-between;
}

@media only screen and (max-width: 992px) {
  .survey-row, .survey-row-reverse {
    flex-direction: column-reverse;
    margin-left: 0px;
  }

  .survey-row .image-container, .survey-row-reverse .image-container {
    margin: 30px 0px !important;
  }
}