@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.text_inp_add_comm .ant-input {
    background-color: #F5F5F5;
    height: 2.85em !important;
    border-radius: 0.5em !important;
    border: unset !important;

}

.text_inp_add_comm {
    font-size: 1.1em;
    font-weight: 600;
    font-family: poppins;
}

.tool_add_comm {
    font-size: 1.1em;
    font-weight: 600;
    font-family: poppins;
}

.text_inp_add_comm .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.dpick_add_comm {
    background-color: #F5F5F5;
    width: 100%;
    border-radius: 0.5em;
    border: unset !important;
}

.tool_add_comm .ant-select-selector {
    background-color: #F5F5F5 !important;
    height: 2.85em !important;
    border-radius: 0.5em !important;
    border: unset !important;
}

.tool_add_comm .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.main_row {
    padding-left: 0 !important;
}

.ckeditor-parent .ant-col-offset-1 {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.add_btn_green > div > div > button {
    background-color: #A5C42B;
    color: white;
    font-weight: 600;
    font-family: poppins;
    padding: 1.4em 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add_btn_green > div > div > button:hover {
    background-color: #A5C42B;
    color: white;
    font-weight: 600;
    font-family: poppins;
    padding: 1.4em 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.master_col .ant-col-offset-1  {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.desc_input_text #description {
    height: 11em !important;
    background-color: #F5F5F5;
    border-radius: 0.5em !important;
    border: unset !important;

}

.desc_input_text {
    font-size: 1.1em;
    font-weight: 400;
    font-family: poppins;
    background-color: #f5f5f5;
    border: unset;
    box-shadow: unset;
    height: 8em !important;
    margin-bottom: 2.3em;
    margin-top: 0.7em;
    border-radius: 0.5em !important;
    resize: unset;
}

.desc_input_text:focus  {
    border-color: unset !important;
    box-shadow: unset;

}






