@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only screen and (max-width: 480px) {
  h1 {
    font-weight: bold;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
  }
  h3 {
    font-size: 12px;
    font-weight: bold;
  }
}
.ant-form-item-label > label {
  height: auto;
}
.ant-radio-inner {
  width: 20px;
  height: 20px;
  border-width: 3px;
}

.ant-pagination-simple .ant-pagination-simple-pager {
  height: 32px;
}

.ant-checkbox-wrapper.ant-transfer-list-checkbox {
  display: none;
}

/* .ant-collapse-item-active > .ant-collapse-header {
  background: linear-gradient(111.92deg, #e6e6e6 36.07%, rgba(55, 12, 94, 0) 85.34%);
  box-shadow: 0px 4px 10px rgb(32 32 32 / 10%);
  margin-bottom: 10px;
} */
/* .ant-collapse-header:hover {
  background: linear-gradient(111.92deg, #e6e6e6 36.07%, rgba(55, 12, 94, 0) 85.34%);
} */

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  background: #f5f5f5;
}

.ant-checkbox-wrapper .ant-transfer-list-checkbox {
  display: none;
}
.ant-modal-content {
  border-radius: 10px;
}
.ant-modal-header {
  border-radius: 10px 10px 0 0;
}
.ant-table-cell {
  padding: 10px 16px !important;
}
.ant-table-cell .ant-table-wrapper {
  max-width: 95% !important;
}
.ant-comment-content-author {
  justify-content: space-between !important;
}
/* .css-fycli8 > .css-oalq6z > .css-n4cq1w::before {
  height: unset;
} */

/* Spinner */
.ant-spin-nested-loading > div > .ant-spin-lg > .ant-spin-dot {
  position: fixed !important;
}

/* Card */
.ant-card-bordered .ant-card-cover {
  margin: 0;
}

.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  border-radius: 0;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-line {
  line-height: 0;
}
.ant-progress-inner {
  border-radius: 0;
}

/* ProgressCounterBtn */

.base-timer {
  position: relative;
  width: 30px;
  height: 30px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.ant-form-item-required {
  display: -webkit-box !important;
}
