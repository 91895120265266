.res_anchor_hover:last-child:hover .ant-space-item > div {
    color: green;
}

.res_anchor {
    word-wrap: normal;
    color: white;
}

/* CommitmentView.css */

/* Apply CSS transitions to the span property */
.transition-col {
    transition: all 0.57s ease; /* You can adjust the duration and easing as needed */
  }
  
  /* Define styles for the visible state */
  .transition-col.visible {
    width: 100%;
  }
  
  /* Define styles for the hidden state */
  .transition-col.hidden {
    width: 100%;
  }

  .antD_circle .ant-progress-inner {
    width: 200px !important;
    height: 200px !important;
  }
  