/* :root {
    --final-height: 80%;
  } */

.container {
    width: 500px;
    height: 400px;
    overflow: hidden;
    position: relative;
    /* margin: 50px auto; */
  }
  
  .barcontainer {
    background-color: rgba(217, 217, 217, 1);
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    margin-left: 25px;
    margin-right: 25px;
    width: 60px;
    height: 320px;
    float: left;
    /* //border darken(#98AFC7, 40%) 3px solid */
  }
  
  .bar {
    background-color: var(--inner-color);
    position: absolute;
    bottom: 0;
    width: 100%;
    /* height: 70%; */
    /* border-top: 6px solid #fff; */
    box-sizing: border-box;
    transform-origin: bottom;
  }
  
  .animate {
    animation: grow 1.5s ease-out forwards;
  }
  
  @keyframes grow {
    to {
        height: var(--final-height); /* Change this value to set the final height of the progress bar */
    }
  }
  

