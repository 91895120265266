.site-logo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
}

.nav-menu-logged-in li {
  border-bottom: none !important;
}

.menu-item {
  color: #fff !important;
  font-weight: 700 !important;
  font-size: medium !important;
}

.menu-item.admin-menu {
  width: 40px;
  height: 40px;
  display: flex;
  color: #000000 !important;
  margin-bottom: 15px;
  padding-left: 10px;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  background-color: #afe223;
}

.menu-item-no-profile-pic .ant-menu-submenu-title {
  width: 60px !important;
  height: 60px !important;
  display: flex !important;
  color: #000000 !important;
  font-size: larger !important;
  align-items: center !important;
  border-radius: 100px !important;
  justify-content: center !important;
  background-color: #afe223 !important;
}

.user-profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 100px;
}

.ant-drawer-body .menu-item {
  color: #000000 !important;
  font-size: small !important;
  font-weight: 500 !important;
}

.landing-page-user-login {
  background: #b0cb1f !important;
  color: #fff !important;
  padding: 10px 20px !important;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: small;
  font-family: Poppins;
}

ul.ant-menu li {
  padding-bottom: 7px !important;
}

ul[id="home-overflowed-indicator$Menu"] .menu-item {
  color: #000000 !important;
  font-size: small !important;
  font-weight: 500 !important;
}

.nav-background {
  height: 90px;
  background-color: #000000;
  box-shadow: 0px 4px 10px rgba(32, 32, 32, 0.1);
}

.nav-background-logged-in {
  height: 90px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(32, 32, 32, 0.1);
  border-bottom: 1px solid #000000;
}

.left-drawer-menu {
  padding: 12px 24px 0px;
  display: flex;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: #000000;
}

.right-drawer-menu {
  padding: 12px 0 0 65%;
  display: flex;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: #000000;
}

.sidebar-drawer-menu {
  display: flex;
  justify-content: left;
  align-items: center;
}

.sidebar-drawer-menu svg {
  width: 20px;
}

.navbar-section-title {
  position: absolute;
  display: flex;
  align-items: center;
  left: -50px;
  top: 0px;
  width: calc(100vw - 275px);
  margin-left: 275px;
  padding-left: 25%;
  height: 90px;
  font-size: large;
  font-family: "Poppins";
  font-weight: 600;
}

@media only screen and (max-width: 480px) {
  .nav-background {
    padding: 0;
  }
}

@media only screen and (max-width: 991px) {
  .site-logo-container-lg-0 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
