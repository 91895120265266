.home-sidebar-menu::after {
  border-right: unset !important;
}

ul.ant-menu li.home-sidebar-menu {
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 10px !important;
  padding: 15px 25px !important;
  height: unset !important;
  font-family: Poppins;
  font-size: "17px";
}

ul.ant-menu li.home-sidebar-menu.ant-menu-item-selected {
  background-color: #b0cb1f !important;
  color: #32332b !important;
}

.ant-menu-item-selected .home-sidebar-icons {
  fill: #32332b !important;
}

.ant-menu-item .home-sidebar-icons{
  fill:#fff;
}

.home-body-container {
  margin-top: 25px;
}

.sidebar-image-container {
  position: fixed;
  display: flex;
  z-index: 100;
  height: 90px;
  top: 0px;
  padding-left: 50px;
  align-items: center;
}

.sidebar-drawer .home-sidebar-menu::after {
  border-right: 2px solid #b0cb1f;
}

.sidebar-drawer ul.ant-menu li.home-sidebar-menu, 
.sidebar-drawer .ant-menu-item .home-sidebar-icons {
  color: #000;
  fill: #000 !important;
}

@media only screen and (max-width: 991px) {
  .sidebar-image-container {
    display: none;
  }
}

