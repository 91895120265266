.team_pulse_bar .ant-progress-bg {
    height: 3em !important;
}

.horizontal_prog .ant-progress-bg{
    height: 0.9em !important;
}

.horizontal_prog .ant-progress-text{
    /* visibility: hidden !important; */
    display: none !important;
}

.horizontal_prog {
    margin: 0 0 0.6em 0;
}

.team_pulse_bar .ant-progress-text {
    font-weight: 600;
    
}

