/* CommitmentView.css */

/* Apply CSS transitions to the span property */
.transition-col {
    transition: all 0.57s ease; /* You can adjust the duration and easing as needed */
  }
  
  /* Define styles for the visible state */
  .transition-col.visible {
    width: 100%;
  }
  
  /* Define styles for the hidden state */
  .transition-col.hidden {
    width: 100%;
  }

.progress_bars .ant-spin-nested-loading {
  display: flex;
}

.progress_bars .ant-spin-container {
  display: flex;
  flex-direction: column;
}
  