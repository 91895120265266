.spin_for_survey {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 50%;
  margin-top: 25%;
}

.survey-card-container{
  width: 100%;
  display: flex;
  margin: 0px 12px;
}

.survey-tab-container .ant-tabs-nav::before {
  border: none !important;
}

.survey-tab-container .ant-tabs-nav-list {
  border-radius: 20px;
  padding: 10px 10px;
}

.survey-tab-container .ant-tabs-tab {
  padding: 0px;
}

.survey-tab-container .ant-tabs-tab h2 {
  color: #fff !important;
  background-color: unset !important;
  margin: 0px !important;
  padding: 15px;
  font-size: small;
  border-radius: 10px;
}

.survey-tab-container .ant-tabs-tab-active h2 {
  color: #32332b !important;
  background-color: #afe223 !important;
  margin: 0px !important;
  padding: 15px;
}

.survey-cards {
  border-radius: 25px;
  background: #c3d5601a;
  border: unset;
  display: flex;
  width: 100%;
}

.survey_card_icon {
  padding: 24px !important;
  background: linear-gradient(
    180deg,
    rgba(185, 196, 126, 0.1) 0%,
    rgba(89, 94, 60, 0) 100%
  );
  border-top-left-radius: 25px;
  width: 20%;
}

.survey_card_content {
  padding: 24px !important;
  width: 80%;
}

.survey_card_content h2 {
  text-align: start;
}

.survey_card_content button {
  font-size: larger;
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
  border-radius: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  width: 200px;
  font-family: Poppins;
  font-weight: 600;
  border: 3px solid black;
  background: unset;
  margin-top: 20px;
}

.survey_card_content button:hover {
    border: 3px solid black;
    background: #B0CB1F;
  }

.survey_card_content button a {
  color: #32332b;
}

@media only screen and (max-width: 720px) {
  .survey-cards{
    flex-direction: column;
  }

  .survey_card_icon{
    width: 100%;
    border-radius: 25px;
  }
}